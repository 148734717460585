$primary: #106c12;
$primary-light: lighten($primary, 5%);

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  color: white !important;

  &::before {
    background-color: $primary-light !important;
    border-color: $primary-light !important;
  }

  &:hover {
    border-color: $primary-light !important;
  }
}


.btn-outline-primary {
  border-color: $primary !important;
  color: $primary !important;

  &::before {
    background-color: transparent !important;
    border-color: $primary-light !important;
  }

  &:hover {
    border-color: $primary-light !important;
    //color: white !important;
  }

  &:active {
    border-color: $primary !important;
    color: $primary !important;
  }
}

.text-primary {
  color: $primary !important;
}

.bg-white-darker {
  background-color: darken(white, 2%);
}

.pill-primary {
  background-color: $primary;
  color: white;

  svg {
    color: white;
  }
}

.pill-danger {
  background-color: #dc3545;
  color: white;

  svg {
    color: white;
  }
}

.pill-secondary {
  background-color: #f4f4f4;
  color: $primary !important;

  svg {
    color: $primary;
  }
}
