.gallery {
  &__image {
    position: relative;

    img {
      object-fit: cover;
      height: 150px;
    }

    &:hover {
      cursor: pointer;

      .gallery__image-info {
        //visibility: visible;
      }
    }

    &__selected {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 32px;
      padding: 5px;
      color: white;
      background: linear-gradient(to right, rgba($primary, 0.9) 25%, transparent 55%);
    }
  }

  &__image-info {
    position: absolute;
    top: 0;
    background-color: white;
    width: 100%;
    transition: all 0.2s;
    visibility: hidden;
    padding: 5px;
  }

  &__upload-image {
    height: 150px;
    display: table;
    width: 200px;

    p {
      user-select: none;
    }

    div {
      display: table-cell;
      vertical-align: middle;
    }

    &:hover {
      cursor: pointer;

      transform: scale(1.025);
      transition: all 0.1s;
    }
  }

  &__delete-confirm {
    table {
      width: 100%;
      text-align: left;

      th {
        padding-bottom: 10px;
      }

      td {
        padding-bottom: 20px;

        img {
          width: 100px;
          height: 60px;
          object-fit: cover;
        }
      }
    }
  }
}
